import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 17; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/16-aug-2/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function AazadiKaAmritMahotsav() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Azadi ka Amrit Mahotsav at Chinmaya Vidyalaya, New Delhi.
        </h1>

        <p>
          Honoring the past, embracing the present and envisioning the glorious
          future for our beloved India, Chinmaya Vidyalaya,New Delhi, celebrated
          the 77th Independence Day by engaging the students and the parents in
          a plethora of patriotic activities under the 'Har Ghar Tiranga'
          initiative.
          <br />
          <br />
          The Pre- Primary Department interacted with the parents of the little
          learners and enlivened the discussion with a quiz on National symbols,
          famous patriotic songs, leaders and the National Flag.
          <br />
          <br />
          The students of Primary Department enjoyed preparing tri- coloured
          food items, drawing and colouring activities. The parents and the
          students sang songs in praise for their beloved nation and preserved
          their moments of togetherness, love and pride in short videos. The
          Middle and Senior School students posted their selfies with a
          'Tiranga' and deepened their connect with the National Flag.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
